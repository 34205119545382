<template>
  <ClFormRow :label="label" :toolTipText="tooltip" name="entityRefId" ref="selectedRefIds">
    <template #content>
      <multiselect
        v-model="selectedRefIds"
        :options="entityOptions"
        :multiple="isMultiple"
        :hide-selected="true"
        :selectLabel="selectLabel"
        :block-keys="['Tab', 'Enter']"
        required
        label="name"
        track-by="id"
        class="custom-multi-select zq--multiselect"
      />
    </template>
  </ClFormRow>
</template>

<script>
import {formConfig} from "@/config";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ZqSelectEntityRefIds",
  props: {
    value: [Array, String],
    label: String,
    tooltip: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    isMultiple: { type: Boolean, default: true },
  },
  data() {
    return {
      selectLabel: formConfig.selectLabel,
      selectedRefIds: [],
    }
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('products', ['products']),
    entityOptions() {
      let entityOptions = [];
      if (this.products) {
        this.products.forEach(product => {
          entityOptions.push({
            name: product.name,
            id: product.id
          })
        })
      }
      return entityOptions;
    },
  },
  watch: {
    selectedRefIds: {
      deep: true,
      handler(val) {
        if (val && (val.length || Object.keys(val).length)) {
          this.$refs.selectedRefIds.$el.classList.remove('zq-invalid');
        }
        let refIds = '';
        if (this.isMultiple) {
          refIds = this.selectedRefIds.map(item => item.id);
        } else {
          refIds = this.selectedRefIds.id;
        }
        this.$emit('input', refIds);
      }
    }
  },
  methods: {
    ...mapActions('products', ['handleGetProducts']),
    initialize() {
      this.handleGetProducts({
        id: [],
        limit: 9999,
        skip: 0,
      });
    },
  }
}
</script>

<style scoped>

</style>